/**
 * 娱乐区路由方法
 */

export const EntertainmentArea = () => {
  return {
    /** 游戏大厅 */
    gameLobby: '/entertainment-area/gameLobby',
    /** 额度转换 */
    quotaConversion: '/entertainment-area/quota-conversion',
    /** 划转记录 */
    transferRecords: '/entertainment-area/transfer-records',
    /** 报表记录 */
    reportRecords: '/entertainment-area/report-records',
    /** 我的返水 */
    myRebate: '/entertainment-area/my-rebate',
  }
}
